import styles from "./Account.module.css";
import user from "../icons/user.png";

export default function Account({ username, onClick }) {
    if (username == null) {
        return (
            <button className={styles.wrapper} onClick={onClick}>
                    <p>Login</p>
            </button>
          );
    }
    else {
        return (
            <button className={styles.wrapper} onClick={onClick}>
                <img
                    src={(user)}
                    className={styles.usericon}
                    alt="user-icons"
                />
                <p>{username}</p>
            </button>
          );
    }
  
}