import styles from "./NewSession.module.css";

import { useState, useEffect, useRef } from "react";

export default function NewSession({ show, availableModels, handleCreateSession, handleClose }) {

  const [isOpen, setIsOpen] = useState(false);
  const [temporaryChat, setTemporaryChat] = useState(false);
  const [sessionName, setSessionName] = useState("");
  const [model, setModel] = useState(availableModels[0]);
  const [expireTime, setExpireTime] = useState(0);
  const [error, setError] = useState(false)

  const dropdownRef = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // function operationTransform(operation) {
  //     const str2sym_mapping = {
  //         "equals": "=",
  //         "notEquals": "!=",
  //         "stringContains": ":="
  //     }
  //     const sym2str_mapping = {
  //         "=": "equals",
  //         "!=": "notEquals",
  //         ":=": "stringContains"
  //     }
  //     if (operation in str2sym_mapping) {
  //         return str2sym_mapping[operation]
  //     }
  //     else if (operation in sym2str_mapping) {
  //         return sym2str_mapping[operation]
  //     }
  //     return undefined
  // }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const handleItemClick = (item) => {
    setModel(item)
    setIsOpen(false);
  };

  const handleTemporaryChat = () => {
    setTemporaryChat(!temporaryChat)
  }

  const handleExpireTime = (e) => {
    setError(false)
    setExpireTime(parseInt(e))
  }

  const handleCreateSessionSubmit = () => {
    if ((temporaryChat && !(typeof expireTime === 'number' && isFinite(expireTime))) || 
    (temporaryChat && expireTime < 1)) {
      setError(true)
    }
    else if (temporaryChat) {
      handleCreateSession(sessionName, model, expireTime);
      handleClose();
    }
    else {
      handleCreateSession(sessionName, model);
      handleClose();
    }
  }


  return (
    <div className={show ? `${styles.modal} ${styles.show}` : styles.modal} onClick={handleClose}>
      <div className={styles.modal_content} onClick={e => e.stopPropagation()}>
        <span className={styles.close} onClick={handleClose}>&times;</span>
        <div className={styles.modal_header}>
          <h2>Session</h2>
        </div>
        <div className={styles.divider}></div>
        <div>
          <div className={styles.session_name}>
            <input
              onChange={(e) => setSessionName(e.target.value)}
              placeholder="Session name"
            />
          </div>
          <div className={styles.session_model}>
            <div className={styles.dropdown} ref={dropdownRef}>
              <div className={`${styles.dropdown_toggle} ${isOpen ? styles.focus : ''}`} onClick={toggleDropdown}>
                {model}
              </div>
              <ul className={`${styles.dropdown_menu} ${isOpen ? styles.show : ''}`}>
                {availableModels.map((el, index) => (
                  <li key={index} onClick={() => handleItemClick(el)}>{el}</li>
                ))}
              </ul>
              <p>Select a model for the chat session. There can be only a model per chat session. To see what model is currently used, click your username in the main page.</p>
            </div>
          </div>
          <div className={styles.session_temporary_chat}>
            <div className={styles.temporary_chat_question}>
              <p>Temporary chat?</p>
              <input
                type="checkbox"
                checked={temporaryChat}
                onChange={handleTemporaryChat}
                className={styles.temporary_chat_checkbox}
              />
            </div>
            {temporaryChat ? (
              <div className={`${styles.temporary_chat_time} ${error ? styles.error : ''}`}>
                <p>Retain for:</p>
                <input
                onChange={(e) => handleExpireTime(e.target.value)}
                value={expireTime}
                />
                <p>Minutes</p>
              </div>

            ) : (<div />)}
          </div>
        </div>
        <div className={styles.submit}>
          <button disabled={!(sessionName !== "" && model !== "")} onClick={handleCreateSessionSubmit}>Create</button>
        </div>
      </div>

    </div>

  )
}