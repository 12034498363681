import { marked } from 'marked'

// const renderer = new marked.Renderer();

// renderer.codespan = function(text) {
//     // Use <strong> for bold text
//     return '<strong>' + text + '</strong>';
//   };

marked.setOptions({
    breaks: true, // Enable GFM line breaks
    // pedantic: true
    // renderer: renderer
});

const preprocessMarkdown = (markdown) => {
    return markdown.replace(/(```[\s\S]*?```)|`([^`]+)`/g, (match, p1, p2) => {
        if (p1) {
            return p1; // Return the triple backtick match as is
        }
        if (p2) {
            return '**' + p2 + '**'; // Replace single backticks with double asterisks
        }
        return match; // Return other matches as is (should not happen in this context)
    });
};

export default function Markdown({ markdown }) {
    if (markdown) {
        // console.log(markdown);
        // console.log(marked(markdown));
        markdown = preprocessMarkdown(markdown)
        return (
            <div dangerouslySetInnerHTML={{ __html: marked(markdown) }} />
        );
    }
    return (
        <div>
            <p>AN UNKNOWN ERROR OCCURRED.</p>
        </div>
    );

};