import styles from "./Session.module.css";
import { useState, useEffect } from "react";

export default function Session({ session_id, session_name, handleDeleteSession, isSelected, onClick }) {
  
  const [isHovered, setIsHovered] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [fadeTooltip, setFadeTooltip] = useState(false);

  useEffect(() => {
    let timer;
    if (isHovered) {
      timer = setTimeout(() => {
        setShowTooltip(true);
        setTimeout(() => {
          setFadeTooltip(true);
        }, 10); // Small delay to trigger CSS transition
      }, 3000);
    } else {
      setFadeTooltip(false);
      timer = setTimeout(() => {
        setShowTooltip(false);
      }, 500); // Should match the fade-out duration
    }
    return () => clearTimeout(timer);
  }, [isHovered]);

  const handleDeleteButton = () => {
    handleDeleteSession(session_id)
  }

  
  
  return (
    <div >
      <div className={`${styles.container} ${isSelected ? styles.selected : ""}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      >
      <div 
      className={styles.wrapper}
      onClick={onClick}
      >
      <p>{session_name.substring(0, 15)} ...</p>
    </div>
    {showTooltip && (
        <div className={`${styles.tooltip} ${fadeTooltip ? styles.fade_in : styles.fade_out}`}>{session_name}</div>
      )}
    <button className={`${styles.closeButton} ${isHovered ? styles.show : ''}`} onClick={handleDeleteButton}>&times;</button>
    </div>
    <div className={styles.divider}/>
    </div>
    
  );
}