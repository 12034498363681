import styles from "./Loading.module.css";
// import { waveform } from 'ldrs'
import { dotWave } from 'ldrs'

// waveform.register()

// Default values shown



dotWave.register()


export default function Loading({size="30", speed="0.8", color="black"}) {
    return(
        <div className={styles.wrapper}>
            <l-dot-wave
            size={size}
            speed={speed}
            color={color}
            ></l-dot-wave>
        </div>
        
    )
}
// Default values shown


// Default values shown
// export default function Loading({size="35", speed="0.7", stroke="3.5", color="black"}) {
//     return(
//         <div className={styles.wrapper}>
//             <l-waveform
//             size={size}
//             stroke={stroke}
//             speed={speed}
//             color={color}
//             ></l-waveform>
//         </div>
        
//     )
// }
