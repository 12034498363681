import styles from "./Attachment.module.css";
import Loading from "./Loading";
import paper_clip from "../icons/paper-clip.png"

export default function Settings({ show, isLoading, handleClose, attachmentList, handleSelect, handleDelete }) {

    const attachmentLevel = (attachment_name, key, model) => {
        return (
            <li key={key}>
                <div className={styles.attachment_list}>
                    <div className={styles.filename}>
                    <p>{attachment_name}</p>
                    </div>
                    <div className={styles.action_button} onClick={() => handleSelect(attachment_name)}>
                        <img
                            src={paper_clip}
                            className={styles.icon}
                            alt='use-as-filter'
                        />
                    </div>
                    <div className={styles.action_button} onClick={() => handleDelete(attachment_name, model)}>
                        <span>&times;</span>
                    </div>
                </div>
                <div className={styles.divider}></div>
            </li>
        )
    }

    const modelsLevel = (model_name) => {
        return (
            <div className={styles.model_level}>
                <h3>{model_name}</h3>
                {attachmentList[model_name].length > 0 ? (
                    <div>
                        <ul>
                            {attachmentList[model_name].map((el, i) => {
                                return attachmentLevel(el, i, model_name)
                            })}
                        </ul>
                    </div>
                ) : (
                    <div className={styles.blank_model_message}>
                        <p>There is no file or this model does not support file attachment.</p>
                    </div>
                )}

            </div>
        )
    }

    return (
        <div className={show ? `${styles.modal} ${styles.show}` : styles.modal} onClick={handleClose}>
            <div className={styles.modal_content} onClick={e => e.stopPropagation()}>
                <span className={styles.close} onClick={handleClose}>&times;</span>
                <div className={styles.modal_header}>
                    <h2>Attachment</h2>
                </div>
                <div className={styles.divider}></div>
                {isLoading ? (
                    <div className={styles.loading}>
                        <Loading 
                        size="36"
                        />
                    </div>
                ) : (
                    <div>
                        {Object.keys(attachmentList).map(key => (
                            <li key={key}>
                                {modelsLevel(key)}
                            </li>
                        ))}
                    </div>
                )}

            </div>
        </div>

    )
}