import styles from "./Login.module.css";
import loginillust from "../image/illust1.png"
import Loading from "./Loading"
import { useState } from "react";
import { CognitoIdentityProviderClient, InitiateAuthCommand } from '@aws-sdk/client-cognito-identity-provider';

const client = new CognitoIdentityProviderClient({ region: 'us-east-1' });

export default function Login({ show, handleClose }) {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('')

        const params = {
            AuthFlow: 'USER_PASSWORD_AUTH',
            ClientId: process.env.REACT_APP_INCOGNITO_CLIENT,
            AuthParameters: {
                USERNAME: username,
                PASSWORD: password,
            },
        };

        try {
            const command = new InitiateAuthCommand(params);
            const response = await client.send(command);
            console.log('Login successful', response);
            // console.log(response);
            localStorage.setItem('accessToken', response.AuthenticationResult.AccessToken);
            localStorage.setItem('idToken', response.AuthenticationResult.IdToken);
            window.location.href = '/';
        }
        catch (error) {
            setError(error.message || 'An error occurred during login');
            console.error('Error logging in', error);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <div className={show ? `${styles.modal} ${styles.show}` : styles.modal} onClick={handleClose}>
            <div className={styles.modal_content} onClick={e => e.stopPropagation()}>
                <span className={styles.close} onClick={handleClose}>&times;</span>
                <div className="Column">
                    <div className={styles.wrapper}>
                        <div>
                            <h2>Hello, welcome!</h2>
                            <h3>For an account, contact DailiTech.</h3>
                        </div>

                        <form onSubmit={handleLogin} className={styles.loginform}>
                            <input
                                type="text"
                                value={username}
                                onChange={(e) => {
                                    setUsername(e.target.value)
                                    if (error) setError('');
                                }}
                                className={`${styles.input} ${error ? styles.error : ''}`}
                                placeholder="Username"
                                required
                            />


                            <input
                                type="password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    if (error) setError('');
                                }}
                                className={`${styles.input} ${error ? styles.error : ''}`}
                                placeholder="Password"
                                required
                            />

                            <div className={styles.submitform}>
                                {isLoading ? (
                                    <div className={styles.loadinganimation}>
                                        <Loading/>
                                    </div>

                                ) : (
                                    <button className={styles.btn} type="submit" disabled={isLoading}>
                                        <p>Login</p>
                                    </button>
                                )}
                            </div>


                        </form>


                    </div>

                </div>
                <div className="Column">
                    <img
                        src={(loginillust)}
                        className={styles.illustration}
                        alt="login page illustration"
                    />
                </div>


            </div>
        </div>

    )
}