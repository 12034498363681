import styles from "./Settings.module.css";

export default function Settings({ show, model, username, handleAttachment, handleLogout, handleClose, handleSettingsCreateSession, handleFilter, handleHelp }) {

    return (
        <div className={show ? `${styles.modal} ${styles.show}` : styles.modal} onClick={handleClose}>
            <div className={styles.modal_content} onClick={e => e.stopPropagation()}>
                <span className={styles.close} onClick={handleClose}>&times;</span>
                    <div className={styles.modal_header}>
                        <h2>Hello, {username}! 👋</h2>
                        { model && (<p>Current model: {model}</p>)}
                    </div>
                    
                    <div className={styles.divider}></div>
                    <button
                        onClick={handleSettingsCreateSession}
                        className={`${styles.btn} ${styles.hovernormal}`}
                    >
                        <p>Create Chat Session</p>
                    </button>
                    <div className={styles.divider}></div>
                    <button
                        onClick={handleFilter}
                        className={`${styles.btn} ${styles.hovernormal}`}
                    >
                        <p>Knowledgebase Filter</p>
                    </button>
                    <div className={styles.divider}></div>
                    <button
                        onClick={handleAttachment}
                        className={`${styles.btn} ${styles.hovernormal}`}
                    >
                        <p>Attachment</p>
                    </button>
                    <div className={styles.divider}></div>
                    <button
                        onClick={handleHelp}
                        className={`${styles.btn} ${styles.hovernormal}`}
                    >
                        <p>Help</p>
                    </button>
                    <div className={styles.divider}></div>
                    <button
                        onClick={handleLogout}
                        className={`${styles.btn} ${styles.hoveralert}`}
                    >
                        <p>Logout</p>
                    </button>
                    <div className={styles.divider}></div>
                    <div className={styles.modal_footer}>
                        <p>About DailiTech, visit <a target="_blank" rel="noreferrer" href="https://www.dailitech.com/">our website</a>. <br/>DailiTech Bedrock ChatBot @Version 0.0.1</p>
                    </div>
                    
            </div>
        </div>

    )
}