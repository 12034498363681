import styles from "./CreateFilter.module.css";

import { useState, useEffect, useRef } from "react";

export default function CreateFilter({ show, handleSubmit, handleClose }) {

    const AVAILABLE_OPERATIONS = ["=", "!=", ":="]

    const [isOpen, setIsOpen] = useState(false);
    const [operation, setOperation] = useState('=');
    const [expression, setExpression] = useState("");
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [error, setError] = useState(false);

    const dropdownRef = useRef(null);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // function operationTransform(operation) {
    //     const str2sym_mapping = {
    //         "equals": "=",
    //         "notEquals": "!=",
    //         "stringContains": ":="
    //     }
    //     const sym2str_mapping = {
    //         "=": "equals",
    //         "!=": "notEquals",
    //         ":=": "stringContains"
    //     }
    //     if (operation in str2sym_mapping) {
    //         return str2sym_mapping[operation]
    //     }
    //     else if (operation in sym2str_mapping) {
    //         return sym2str_mapping[operation]
    //     }
    //     return undefined
    // }

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
        // console.log(isOpen);
    };
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };
    const handleItemClick = (item) => {
        setError(false);
        setOperation(item);
        setExpression(`${key} ${item} ${value}`);
        setIsOpen(false);
    };
    const handleChangeExpression = (e) => {
        setError(false);
        setExpression(e);
        var tokens = e.split(' ');
        if (tokens.length > 3) {
            tokens = [tokens[0], tokens[1], tokens.slice(2).join(' ')];
        }
        if (tokens[0]) {
            setKey(tokens[0])
        }
        else {
            setKey("")
        }
        if (tokens[1] && tokens[1].length > 2) {
            setOperation(tokens[1].substring(0, 2))
        }
        else if (tokens[1]) {
            setOperation(tokens[1])
        }
        else {
            setOperation("=")
        }
        if (tokens[2]) {
            setValue(tokens[2])
        }
        else {
            setValue("")
        }

    };
    const handleChangeKey = (e) => {
        setError(false);
        setKey(e);
        setExpression(`${e} ${operation} ${value}`);
    };
    const handleChangeValue = (e) => {
        setError(false);
        setValue(e);
        setExpression(`${key} ${operation} ${e}`);
    };
    const handleFilterSubmit = () => {
        var tokens = expression.split(' ');
        if (tokens.length > 3) {
            tokens = [tokens[0], tokens[1], tokens.slice(2).join(' ')];
        }
        if (tokens.length !== 3 || !(AVAILABLE_OPERATIONS.includes(tokens[1])) || key === "" || value === "") {
            setError(true)
        }
        else {
            handleSubmit(expression);
            handleClose();
        }
    }


    return (
        <div className={show ? `${styles.modal} ${styles.show}` : styles.modal} onClick={handleClose}>
            <div className={styles.modal_content} onClick={e => e.stopPropagation()}>
                <span className={styles.close} onClick={handleClose}>&times;</span>
                <div className={styles.modal_header}>
                    <h2>Filter</h2>
                </div>
                <div className={styles.divider}></div>
                <div className={`${styles.expression} ${error ? styles.error : ''}`}>
                    <input onChange={(e) => handleChangeExpression(e.target.value)} value={expression} placeholder="Expression"></input>
                </div>
                {error ? <p className={styles.error_text}>Wrong expression</p>: <div/>}
                <div className={styles.divider}></div>
                <div className={styles.token_form}>
                    <input value={key} onChange={(e) => handleChangeKey(e.target.value)} placeholder="Key"></input>
                    <div className={styles.dropdown} ref={dropdownRef}>
                        <div className={styles.dropdown_toggle} onClick={toggleDropdown}>
                            {operation}
                        </div>
                        <ul className={`${styles.dropdown_menu} ${isOpen ? styles.show : ''}`}>
                            <li onClick={() => handleItemClick('=')}>Equals (=)</li>
                            <li onClick={() => handleItemClick('!=')}>Not Equal (!=)</li>
                            <li onClick={() => handleItemClick(':=')}>String Contains (:=)</li>
                        </ul>
                    </div>
                    <input value={value} onChange={(e) => { handleChangeValue(e.target.value) }} placeholder="Value"></input>
                </div>
                <div className={styles.submit}>
                    <button disabled={!(key !== "" && value !== "")} onClick={handleFilterSubmit}>Create</button>
                </div>
            </div>

        </div>

    )
}