import { useEffect, useRef, useState } from 'react';
import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-providers";

import paper_clip from '../icons/paper-clip.png'
// import document_icon from '../icons/document.png'

import Loading from './Loading'

import styles from "./Input.module.css";

export default function Input({ value, user, onChange, onClick, isLoading, model, botErrorHandle, createFilter, createSession }) {
    const textareaRef = useRef(null);
    const wrapperRef = useRef(null);
    const [maxHeight] = useState(128);
    const [isUploading, setIsUploading] = useState(false);

    // const [isHovered, setIsHovered] = useState(false);
    // const [showTooltip, setShowTooltip] = useState(false);
    // const [fadeTooltip, setFadeTooltip] = useState(false);

    // useEffect(() => {
    //     let timer;
    //     if (isHovered) {
    //         timer = setTimeout(() => {
    //             setShowTooltip(true);
    //             setTimeout(() => {
    //                 setFadeTooltip(true);
    //             }, 10); // Small delay to trigger CSS transition
    //         }, 1000);
    //     } else {
    //         setFadeTooltip(false);
    //         timer = setTimeout(() => {
    //             setShowTooltip(false);
    //         }, 500); // Should match the fade-out duration
    //     }
    //     return () => clearTimeout(timer);
    // }, [isHovered]);

    useEffect(() => {
        const textarea = textareaRef.current;
        const wrapper = wrapperRef.current;
        textarea.style.height = 'auto';
        wrapper.style.height = 'auto';
        const currentScrollHeight = textarea.scrollHeight;
        if (currentScrollHeight > maxHeight) {
            textarea.style.height = `${maxHeight}px`;
            wrapper.style.height = `${maxHeight}px`;
        } else {
            textarea.style.height = `${currentScrollHeight}px`;
            wrapper.style.height = `${currentScrollHeight}px`;
        }
    }, [value, maxHeight]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (value.trim() !== "") {
                onClick();
                onChange({ target: { value: '' } });
            }
        }
    };



    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file !== undefined) {
            // Check file size (Max size: 5MB)
            const maxSize = 10 * 1024 * 1024; // 5MB in bytes
            if (file.size > maxSize) {
                botErrorHandle('File size exceeds the maximum limit of 10MB.');
                return;
            }

            // botErrorHandle('Sorry, this feature will be implemented in the near future.');
            // return;
            const uploadParams = {
                Bucket: process.env.REACT_APP_FILE_DUMP_BUCKET + (model.startsWith("claude3") ? "" : `-${model}`), // Replace with your S3 bucket name
                Key: `${user}/${file.name.replaceAll(" ", "_")}`, // The name of the file in S3
                Body: file,
            };

            try {
                const userPoolId = `cognito-idp.us-east-1.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`
                const s3Client = new S3Client({
                    credentials: fromCognitoIdentityPool({
                        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
                        logins: {
                            [userPoolId]: localStorage.getItem('idToken')
                        },
                        clientConfig: { region: 'us-east-1' }
                    }),
                    region: 'us-east-1'
                });
                setIsUploading(true)
                const upload = new Upload({
                    client: s3Client,
                    params: uploadParams,
                });

                upload.on("httpUploadProgress", (progress) => {
                    console.log(`Uploaded ${progress.loaded} of ${progress.total} bytes`);
                });

                await upload.done();
                createFilter(`attachment = ${file.name.replaceAll(" ", "_")}`)
                console.log(`File uploaded successfully.`);
                setIsUploading(false)
            } catch (err) {
                console.error("Error uploading file:", err);
                botErrorHandle("Error uploading file. See console for details.");
            }
        }
        

    };

    const handleUpload = () => {
        if (!model) {
            createSession();
            return;
        }
        const fileInput = document.getElementById('fileInput')
        fileInput.click();
    }
    // const removeFile = () => {
    //     console.log(filename);
    //     setFile(null)
    // }

    return (
        <div ref={wrapperRef} className={isLoading || isUploading ? styles.wrapper_loading : styles.wrapper}>
            <div>
                <input
                    type="file"
                    id="fileInput"
                    name="file"
                    accept=".txt,.pdf,.doc,.docx,.xlsx,.csv"
                    onChange={(e) => handleFileChange(e)}
                    className={styles.upload_input}
                    disabled={isUploading}
                />
                <div className={`${styles.upload} ${!isUploading ? styles.available : ""}`}
                    onClick={handleUpload}
                >
                    <img
                        src={paper_clip}
                        className={styles.icon}
                        alt='file-upload'
                    />
                </div>
                {/* {isLoading || isUploading ? (<div />) : (
                    <div className={`${styles.upload} ${filename === null ? styles.available : ""}`}
                        onClick={handleUpload}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                    >
                        {showTooltip && (filename !== null) && (
                            <div className={`${styles.tooltip} ${fadeTooltip ? styles.fade_in : styles.fade_out}`}>
                                <p>{filename}</p>
                                <button className={`${styles.removeButton}`} onClick={removeFile}>&times;</button>
                            </div>
                        )}
                        <img
                            src={filename !== null ? document_icon : paper_clip}
                            className={styles.icon}
                            alt='file-upload'
                        />
                    </div>
                )} */}
            </div>
            <textarea
                ref={textareaRef}
                className={`${styles.text} ${isLoading || isUploading ? styles.textloading : ""}`}
                placeholder={isLoading || isUploading ? (isUploading ? "Bot is trying to get your file ..." : "Bot is thinking ...") : "Ask me anything ... (Shift + Enter to start new line)"}
                value={value}
                onChange={onChange}
                onKeyDown={handleKeyPress}
                disabled={isLoading || isUploading}
            />
            {!isLoading && !isUploading ? (
                <button className={value === "" ? styles.btn : styles.btn_ready} onClick={onClick}>
                    Go
                </button>
            ) : (
                <button className={styles.btn_loading} disabled>

                    <div className={styles.loading}>
                        <Loading 
                        color='white'
                        />
                    </div>

                </button>
            )}

        </div>
    );
};