import { useState, useEffect, useRef } from "react";
import { jwtDecode } from 'jwt-decode'

import Message from "./components/Message";
import Input from "./components/Input";
import Session from "./components/Session";
import Account from "./components/Account";
import Loading from "./components/Loading";
import Login from "./components/Login";
import Settings from "./components/Settings";
import NewSession from "./components/NewSession";
import Chip from "./components/Chip";
import CreateFilter from "./components/CreateFilter";
import Attachment from "./components/Attachment";

import "./App.css";

export default function App() {
    const apiUrl = process.env.REACT_APP_API_URL;
    const apiKey = process.env.REACT_APP_API_KEY;
    const MAX_SESSIONS = 30

    const [input, setInput] = useState("");
    const [messages, setMessages] = useState([]);
    const [messageLoading, setMessagesLoading] = useState(false);
    const [sessionList, setSessionList] = useState([]);
    const [session, setSession] = useState(null);
    const [sessionLoading, setSessionLoading] = useState(true);
    const [modelLoading, setModelLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [loginPage, setLoginPage] = useState(false);

    const [model, setModel] = useState(null);
    const [availableModels, setAvailableModels] = useState([]);
    const [newSessionModal, setNewSessionModal] = useState(false);
    const [newSessionLoading, setNewSessionLoading] = useState(false);

    const [filters, setFilters] = useState([]);
    const [filterModal, setFilterModal] = useState(false);
    const [upperSection, setUpperSectionHeight] = useState("85vh");

    const [attachmentModal, setAttachmentModal] = useState(false);
    const [attachmentList, setAttachmentList] = useState({});
    const [attachmentModalLoading, setAttachmentModalLoading] = useState(false);

    const filterRef = useRef(null);
    const messagesEndRef = useRef(null);
    const userRef = useRef(user);


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const query_session = searchParams.get('session') || null;
        if (query_session !== null) {
            setSession(query_session)
        };

        const checkLoggedIn = () => {
            try {
                const accessToken = localStorage.getItem('accessToken')
                const idToken = localStorage.getItem('idToken')
                if (accessToken !== "null" && idToken !== "null") {
                    const decodedAccessToken = jwtDecode(accessToken);
                    const currentTime = Date.now() / 1000;
                    const isExpired = decodedAccessToken.exp < currentTime;
                    if (isExpired) {
                        localStorage.setItem('accessToken', null);
                    }
                    else {
                        setUser(decodedAccessToken.username);
                    }
                }
                else {
                    setLoginPage(true)
                }
            }
            catch (error) {
                console.error('Error logging in:', error);
            }
        }
        checkLoggedIn();
    }, [])

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.ctrlKey && event.key === 'n') {
                event.preventDefault();
                handleNewSession();
            }
            if (event.ctrlKey && event.key === 'f') {
                event.preventDefault();
                handleCreateFilter();
            }
            if (event.ctrlKey && event.key === 's') {
                event.preventDefault();
                handleAccountOpen();
            }
            if (event.ctrlKey && event.key === 'a') {
                event.preventDefault();
                handleAttachment();
            }
            if (event.ctrlKey && event.key === 'h') {
                event.preventDefault();
                handleGettingStarted();
            }
            if (event.ctrlKey && event.key === 'u') {
                event.preventDefault();
                console.log(user);
            }
            if (event.ctrlKey && ['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(event.key)) {
                event.preventDefault();
                const session_id = sessionList[Number(event.key)-1].sessionId;
                if (session_id) {
                    handleSession(session_id)
                }
            }
            if (event.key === 'Escape') {
                event.preventDefault();
                handleCloseAll();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, sessionList])

    useEffect(() => {
        userRef.current = user; // Update the ref whenever user state changes
    }, [user]);

    useEffect(() => {
        const handleUpperSectionResize = () => {
            if (filterRef.current) {
                const filterHeight = filterRef.current.offsetHeight;
                const sectionHeight = `calc(85vh - ${filterHeight}px)`;
                setUpperSectionHeight(sectionHeight);
            }
        };

        handleUpperSectionResize();
    }, [filters, upperSection])

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const response = await fetch(apiUrl + "/messages/get-sessions", {
                    method: 'POST',
                    headers: {
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('idToken')}`
                    },
                    body: JSON.stringify({
                        'table': 'chat-history',
                        'user': (user ? user : "default")
                    }),
                })
                if (!response.ok) {
                    throw new Error('Request failed with status code: ' + response.status);
                }
                const data = await response.json();
                setSessionList(data.data.sessions);
                setSessionLoading(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const checkLoggedIn = () => {
            try {
                const accessToken = localStorage.getItem('accessToken')
                const idToken = localStorage.getItem('idToken')
                if (accessToken !== "null" && idToken !== "null") {
                    const decodedAccessToken = jwtDecode(accessToken);
                    const currentTime = Date.now() / 1000;
                    const isExpired = decodedAccessToken.exp < currentTime;
                    if (isExpired) {
                        localStorage.setItem('accessToken', null);
                    }
                    else {
                        setUser(decodedAccessToken.username);
                    }
                }
                else {
                    setLoginPage(true)
                }
            }
            catch (error) {
                console.error('Error logging in:', error);
            }
        }
        checkLoggedIn();
        if (user !== null) {
            fetchSession();
        }
    }, [apiKey, apiUrl, user]);

    useEffect(() => {
        const fetchAvailableModels = async () => {
            try {
                const response = await fetch(apiUrl + "/model", {
                    method: 'POST',
                    headers: {
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('idToken')}`
                    },
                    body: JSON.stringify({
                        'user': user
                    }),
                })
                if (!response.ok) {
                    throw new Error('Request failed with status code: ' + response.status);
                }

                const data = await response.json();
                setAvailableModels(data.data.models);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        if (user !== null) {
            fetchAvailableModels();
        }

    }, [user, newSessionModal, apiUrl, apiKey])

    useEffect(() => {
        const fetchAttachment = async (model) => {
            setAttachmentModalLoading(true);
            try {
                const response = await fetch(`${apiUrl}/attachment/list`, {
                    method: 'POST',
                    headers: {
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('idToken')}`
                    },
                    body: JSON.stringify({
                        'user_id': user,
                        'bucket_name': (model === "claude3-sonnet" || model==="claude3-5-sonnet" ? process.env.REACT_APP_FILE_DUMP_BUCKET : `${process.env.REACT_APP_FILE_DUMP_BUCKET}-${model}`)
                    }),
                })
                // if (!response.ok) {
                //     throw new Error('Request failed with status code: ' + response.status);
                // }

                const data = await response.json();
                setAttachmentList(prevState => ({
                    ...prevState,
                    [model]: data.data.files
                }))
            } catch (error) {
                setAttachmentList(prevState => ({
                    ...prevState,
                    [model]: []
                }))
            }
            setAttachmentModalLoading(false);
        };
        if (user !== null) {

            availableModels.forEach(el => {
                fetchAttachment(el);
            })

        }
    }, [user, attachmentModal, availableModels, apiUrl, apiKey])

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        const fetchChatHistory = async (session_id) => {
            try {
                setFilters([])
                setMessagesLoading(true)
                const response = await fetch(apiUrl + "/messages/get-chat-history", {
                    method: 'POST',
                    headers: {
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('idToken')}`
                    },
                    body: JSON.stringify({
                        'table': 'chat-history',
                        'user': (user),
                        'sessionId': session_id
                    }),
                })
                if (!response.ok) {
                    throw new Error('Request failed with status code: ' + response.status);
                }
                const data = await response.json();
                const chatHistory = data.data.messages.chatHistory;
                setModel(data.data.model);
                setFilters(data.data.stateFilters)


                if (chatHistory) {
                    const mappedChatHistory = chatHistory
                        .filter(el => el.content.text)
                        .map(el => ({
                            role: el.role,
                            content: el.content.text,
                        }));

                    if (mappedChatHistory.length > 0) {
                        setMessages((messages) => mappedChatHistory);
                    }
                    else {
                        if (data.data.model === "getting-started") {
                            setMessages([{"role": "assistant", "content": `Hello, ${user}. I am a helper bot. You can ask me anything about this web application, and I will do my best to assist you. Please feel free to ask me right away! For example:
                                \n- "How can I create a new session?"
                                \n- "What are the shortcut keys?"
                                \n- "Why it cannot find anything in my attachment"`, "logtime": Math.floor(Date.now() / 1000)}])
                        }
                        else{
                            setMessages([])
                        }
                    };
                    setMessagesLoading(false)
                }
                else {
                    setMessagesLoading(false)
                }
                



            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (session !== null && user !== null) {

            fetchChatHistory(session)
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set('session', session);
            const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
            window.history.pushState({ path: newUrl }, '', newUrl);

        }
    }, [apiKey, apiUrl, user, session])

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const botErrorHandle = (error_text) => {
        const error_message = {
            role: "error",
            content: error_text
        };
        setMessages([...messages, error_message]);
    };
    const handleAccountOpen = () => {
        setAttachmentModal(false);
        setLoginPage(true);
        setNewSessionModal(false);
        setFilterModal(false);
    };
    const handleLoginClose = () => {
        setLoginPage(false)
    };
    const handleSubmit = async () => {

        // console.log(file);
        var placeholder_role = "assistant"

        const formFilter = () => {
            var filterSet = {}
            var tokens = []
            if (filters.length === 1) {
                const expression = filters[0];
    
                tokens = expression.split(' ');
                if (tokens.length > 3) {
                    tokens = [tokens[0], tokens[1], tokens.slice(2).join(' ')];
                }

                if (tokens[0] === "attachment" && user) {
                    tokens[2] = `${user}/${tokens[2]}`
                }

                const operation_mapping = {
                    "=": "equals",
                    "!=": "notEquals",
                    ":=": "stringContains"
                }
                const operation = operation_mapping[tokens[1]]
                return {
                    [operation]: {
                        "key": tokens[0],
                        "value": tokens[2]
                    }
                }
            }
            else if (filters.length > 1) {
                filterSet = {
                    "andAll": []
                }
                for (let index = 0; index < filters.length; index++) {
                    const expression = filters[index];

                    tokens = expression.split(' ');
                    if (tokens.length > 3) {
                        tokens = [tokens[0], tokens[1], tokens.slice(2).join(' ')];
                    }
    
                    if (tokens[0] === "attachment" && user) {
                        tokens[2] = `${user}/${tokens[2]}`
                    }
    
                    const operation_mapping = {
                        "=": "equals",
                        "!=": "notEquals",
                        ":=": "stringContains"
                    }
                    const operation = operation_mapping[tokens[1]]
    
                    filterSet["andAll"].push({
                        [operation]: {
                            "key": tokens[0],
                            "value": tokens[2]
                        }
                    })
                }
            }
            return filterSet
            
        }

        const fetchInvoke = async () => {
            try {
                const response = await fetch(apiUrl + `/model/${model}`, {
                    method: 'POST',
                    headers: {
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('idToken')}`
                    },
                    body: JSON.stringify({
                        "text": input,
                        "filters": formFilter(),
                        "session": {
                            "session_id": session,
                            "user_id": user,
                            "table": "chat-history"
                        }
                    }),
                })
                if (!response.ok) {
                    throw new Error('Request failed with status code: ' + response.status);
                }
                const data = await response.json();
                return data.data.response
            } catch (error) {
                console.error('Error fetching data:', error);
                placeholder_role = "error"
            }
        };
        if (!user) {
            handleAccountOpen()
        }
        else if (session) {
            const prompt = {
                role: "user",
                content: input,
            };
            setInput("");
            setMessages([...messages, prompt]);
            setMessages((messages) => [
                ...messages,
                {
                    role: "loading",
                    content: null,
                },
            ]);
            setModelLoading(true)
            // Logic
            const response = await fetchInvoke()

            setMessages(prevMessages => {
                // Create a new array excluding the last element
                return prevMessages.slice(0, -1);
            });
            setMessages((messages) => [
                ...messages,
                {
                    role: placeholder_role,
                    content: response,
                },
            ]);
            setModelLoading(false)
        }
        else {
            handleNewSession()
        }


    };
    const handleLogout = () => {
        localStorage.setItem('accessToken', null);
        setUser(null);
        window.location.href = '/';
    };
    const handleSession = async (sessionId) => {
        setSession(sessionId)
        setInput("")
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('session', sessionId);
        const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
    };
    const handleCreateSession = async (session_name, model, expire_in = (-1)) => {
        const fetchCreateSession = async () => {
            try {
                const response = await fetch(apiUrl + "/messages/create-session", {
                    method: 'POST',
                    headers: {
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('idToken')}`
                    },
                    body: JSON.stringify({
                        "user": userRef.current,
                        "session_name": session_name,
                        "model": model,
                        "table": "chat-history",
                        "expire_in": parseInt(expire_in)
                    }
                    ),
                })
                if (!response.ok) {
                    throw new Error('Request failed with status code: ' + response.status);
                }
                const data = await response.json();
                return data.data.sessionId
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        if (sessionList.length < MAX_SESSIONS) {
            setNewSessionLoading(true)
            const session_id = await fetchCreateSession();
            setSessionList([{
                sessionId: await session_id,
                sessionName: session_name
            }, ...sessionList])
            setNewSessionLoading(false)
            setSession(await session_id)
            return session_id
        }
        else {
            console.error("MAX_SESSION REACHED.");
        }

    };
    const handleRemoveFilter = (filterToRemove) => {
        setFilters(filters.filter(_filter => _filter !== filterToRemove));
    };
    const handleCreateFilter = () => {
        setLoginPage(false);
        setNewSessionModal(false);
        setFilterModal(true);
        setAttachmentModal(false);
    };
    const handleCreateFilterClose = () => {
        setFilterModal(false);
    };
    const handleCreateFilterSubmit = (expression) => {
        var new_filters = filters
        if (expression.startsWith('attachment ')) {
            for (let index = 0; index < filters.length; index++) {
                if (filters[index].startsWith('attachment ')) {
                    new_filters.splice(index, 1);
                }
            }
        }
        setFilters([...new_filters, expression])
        // setFilters([...filters, expression])
    }
    const handleNewSession = () => {
        setLoginPage(false);
        setFilterModal(false);
        setNewSessionModal(true);
        setAttachmentModal(false);
    }
    const handleNewSessionClose = () => {
        setNewSessionModal(false);
    }
    const handleDeleteSession = async (session_id) => {
        const fetchDeleteSession = async () => {
            try {
                const response = await fetch(apiUrl + "/messages/delete-session", {
                    method: 'POST',
                    headers: {
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('idToken')}`
                    },
                    body: JSON.stringify({
                        "user": user,
                        "session_id": session_id,
                        "table": "chat-history",
                    }
                    ),
                })
                if (!response.ok) {
                    throw new Error('Request failed with status code: ' + response.status);
                }
                const data = await response.json();
                return data.data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchDeleteSession();
        setSessionList(sessionList.filter(session => session.sessionId !== session_id))
        if (session === session_id) {
            window.history.pushState({ path: `${window.location.pathname}` }, '', `${window.location.pathname}`);
            setSession(null)
            setMessages([])
        }


    }
    const handleAttachment = () => {
        setAttachmentModal(true);
        setLoginPage(false);
        setNewSessionModal(false);
        setFilterModal(false);
    }
    const handleAttachmentClose = () => {
        setAttachmentModal(false);
    }
    const handleSelectAttachment = (attachment_name) => {
        setAttachmentModal(false);
        handleCreateFilterSubmit(`attachment = ${attachment_name}`);
    }
    const handleDeleteAttachment = (attachment_name, model) => {
        const fetchDeleteAttachment = async () => {
            setAttachmentModalLoading(true);
            try {
                const response = await fetch(`${apiUrl}/attachment/delete`, {
                    method: 'POST',
                    headers: {
                        'x-api-key': apiKey,
                        'Content-Type': 'application/json',
                        'Authorization': `${localStorage.getItem('idToken')}`
                    },
                    body: JSON.stringify({
                        'user_id': user,
                        'bucket_name': (model === "claude3-sonnet" || model === "claude3-5-sonnet" ? process.env.REACT_APP_FILE_DUMP_BUCKET : `${process.env.REACT_APP_FILE_DUMP_BUCKET}-${model}`),
                        'file_name': attachment_name
                    }),
                })
                if (!response.ok) {
                    throw new Error('Request failed with status code: ' + response.status);
                }

                await response.json();

                setAttachmentList(prevState => ({
                    ...prevState,
                    [model]: prevState[model].filter(item => item !== attachment_name)
                }));

                if (filters.includes(`attachment = ${attachment_name}`)) {
                    handleRemoveFilter(`attachment = ${attachment_name}`)
                }
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
            setAttachmentModalLoading(false);
        };
        if (user !== null) {
            fetchDeleteAttachment();
        }

    }
    const handleGettingStarted = async () => {
        handleCloseAll()
        if (userRef.current) {
            await handleCreateSession("Getting started", "getting-started");
        }
    }
    const handleCloseAll = () => {
        setLoginPage(false);
        setNewSessionModal(false);
        setFilterModal(false);
        setAttachmentModal(false);
    }

    return (
        <div className="App">
            <div className="Column">
                {/* <h3 className="Title">DailiTech Bedrock ChatBot</h3> */}
                <div className="ContentChat">
                    <div className="MessageChatSection" style={{ height: upperSection }}>
                        {messageLoading ? (
                            <div className="LoadingMiddle">
                                <Loading />
                            </div>
                        ) : (
                            <div>
                                {messages.map((el, i) => {
                                    return <Message key={i} role={el.role} content={el.content} />;
                                })}
                                <div ref={messagesEndRef} />
                            </div>

                        )}
                        {(messages.length === 0 && !messageLoading) ? (
                            <div className="BackgroundText">
                                {(user === null) ? (
                                    <div>
                                        <h2>Welcome to DailiTech Chatbot!</h2>
                                        <p>This web application showcases the demo functionality<br /> and serves as a proof of concept (POC) for Amazon Bedrock.</p>
                                        <p>Please log in to your account to unlock the full functionality of the app.</p>
                                        <p><br />Have a great day!</p>
                                    </div>
                                ) : (
                                    <div>
                                        {(session === null) ? (
                                            <div>
                                                <h2>Welcome back, {user}!</h2>
                                                <p>To begin with, you need to create a session.<br />
                                                    You can either use (+) in the right or Settings &gt; "Create Chat Session"<br />
                                                    Put in a session name and a model. Then, you are good to go!<br /><br />
                                                    Or you can &lt;control+h&gt; to open a new helping session.
                                                </p>

                                                <p><br />Have a great day!</p>

                                                <button className="AltCreateSessionButton" onClick={handleNewSession}>Let's get it started by create a new chat session</button>
                                            </div>
                                        ) : (
                                            <div>
                                                <h2>Welcome back, {user}!</h2>
                                                <p>You can start chatting with the bot. <br />
                                                    Please, remember that your chat messages will be logged.<br />
                                                    You can filter the knowledge base by using filter.<br />
                                                    To do this use Settings &gt; "Knowledgebase Filter"
                                                </p>

                                                <div className="CurrentModel">Current model is: <div className="ModelText">{model}</div></div>
                                                <p>Have a great day!</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div />
                        )}

                    </div>
                    <div className="FilterSection" ref={filterRef}>
                        {true ? (
                            <div>
                                <div className="FilterContainer">
                                    {filters.map((_filter, index) => (
                                        <Chip key={index} label={_filter} onClose={() => handleRemoveFilter(_filter)} />
                                    ))}
                                    {(filters.length > 0) ? (
                                        <div className="AddFilterButton" onClick={handleCreateFilter}>
                                            <span>&#43;</span>
                                        </div>
                                    ) : <div />}
                                </div>
                            </div>
                        ) : <div />}
                    </div>
                    <div className="Input">
                        <Input
                            value={input}
                            user={user}
                            onChange={(e) => setInput(e.target.value)}
                            onClick={input ? handleSubmit : undefined}
                            isLoading={modelLoading}
                            botErrorHandle={botErrorHandle}
                            model={model}
                            createFilter={handleCreateFilterSubmit}
                            createSession={handleNewSession}
                        />
                    </div>
                </div>

            </div>
            <div className="Column">
                <Account username={user} onClick={handleAccountOpen} />
                {user === null ? (
                    <Login
                        show={loginPage}
                        handleClose={handleLoginClose}
                    />
                ) : (
                    <Settings
                        show={loginPage}
                        model={model}
                        username={user}
                        handleAttachment={handleAttachment}
                        handleClose={handleLoginClose}
                        handleLogout={handleLogout}
                        handleSettingsCreateSession={handleNewSession}
                        handleFilter={handleCreateFilter}
                        handleHelp={handleGettingStarted}
                    />
                )}
                {(sessionLoading && user) ? (
                    <div className="Content">
                        <div className="Loading">
                            <Loading
                                size="32"
                                color="#45474B"
                            />
                        </div>
                    </div>
                ) : (
                    <div className="Content">
                        {sessionList.map((el, i) => {
                            return (
                                <Session
                                    key={i}
                                    session_id={el.sessionId}
                                    session_name={el.sessionName}
                                    handleDeleteSession={handleDeleteSession}
                                    onClick={() => {
                                        handleSession(el.sessionId)
                                    }}
                                    isSelected={(el.sessionId === session)}
                                />
                            );
                        })}
                        {user ? (
                            <div>
                                {newSessionLoading ? (
                                    <div className="LoadingMarginTop">
                                        <Loading
                                            size="20"
                                            color="#45474B"
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <div className="CreateSessionButton" onClick={handleNewSession}>
                                            <span>&#0043;</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : undefined}
                    </div>
                )}
            </div>
            {filterModal ?
                <CreateFilter
                    show={filterModal}
                    handleClose={handleCreateFilterClose}
                    handleSubmit={handleCreateFilterSubmit}
                /> : <div />}
            {newSessionModal ?
                <NewSession
                    show={newSessionModal}
                    availableModels={availableModels}
                    handleClose={handleNewSessionClose}
                    handleCreateSession={handleCreateSession}
                /> : <div />}
            {attachmentModal ?
                <Attachment
                    show={attachmentModal}
                    attachmentList={attachmentList}
                    isLoading={attachmentModalLoading}
                    handleSelect={handleSelectAttachment}
                    handleDelete={handleDeleteAttachment}
                    handleClose={handleAttachmentClose}
                /> : <div />}
            {}
        </div>
    );
}