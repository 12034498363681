import Markdown from "./Markdown";
import Loading from "./Loading";

import boticon from "../icons/bot.png"
import usericon from "../icons/user.png"

import styles from "./Message.module.css"

export default function Message({role, content}) {
    if (role === "error") {
        return (
            <div className={styles.wrapper_error}>
                <div>
                    <img
                    src={(boticon)}
                    className={styles.avatar}
                    alt="profile avatar"
                    />
                </div>
                <div className={styles.text}>
                    {/* <p>{content}</p> */}
                    <Markdown 
                    markdown={content}
                    />
                </div>
            </div>
        )
    }
    if (role === "loading") {
        return (
            <div className={styles.wrapper_loading}>
            <div>
                <img
                src={(boticon)}
                className={styles.avatar}
                alt="bot avatar"
                />
            </div>
            <div className={styles.loading}>
                <Loading/>
            </div>
        </div>
        )
    }
    return (
        <div className={role==="assistant" ? styles.wrapper_assistant : styles.wrapper_user}>
            <div>
                <img
                src={role==="assistant" ? (boticon) : (usericon)}
                className={styles.avatar}
                alt="profile avatar"
                />
            </div>
            <div className={styles.text}>
                {/* <p>{content}</p> */}
                <Markdown 
                markdown={content}
                />
            </div>
        </div>
    );
};

